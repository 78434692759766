<template lang="pug">
nav.navegacao-mobile(:class="{ 'visibility-hidden': drawerAberto }")
  router-link.logo(to='/')
      img(src="@/assets/home/logo_sinapse.png")
  .pagina-ativa(@click="drawerAberto=true")
    .label {{ $route.name }}
    img.chevron-down(src="@/assets/icons/chevron-down.svg")

  .navegacao(:class="[drawerAberto&&'slideDown', auxiliar]")
    .linha-topo-fechar
      .logo Sinapse
      .fechar-menu(@click="fecharMenu")
        img(src="@/assets/icons/xmark.svg")

    .links-menus
      .label-menu Menu
      router-link(to='/' @click.native="fecharMenu") Home
      router-link(to='/automacao' @click.native="fecharMenu") Automação
      router-link(to='/eletrica' @click.native="fecharMenu") Elétrica
      router-link(to='/termometria' @click.native="fecharMenu") Termometria
      router-link(to='/infraestrutura' @click.native="fecharMenu") Infraestrutura
      //- router-link(to='/eficienciaenergetica' @click.native="fecharMenu") Eficiência energética
      router-link(to='/empresa' @click.native="fecharMenu") Empresa
      router-link(to='/clientes' @click.native="fecharMenu") Clientes
      router-link(to='/contato' @click.native="fecharMenu") Contato
    .redes-sociais-mobile
      a(href="https://facebook.com/SinapseSolucoeseAutomacoes" target="_blank")
        img(src="@/assets/footer/facebook.svg")
      a(href="https://www.linkedin.com/company/sinapse-soluções-e-automação" target="_blank")
        img(src="@/assets/footer/linkedin-in.svg")
      a(href="http://instagram.com/sinapsesolucoes/" target="_blank")
        img(src="@/assets/footer/instagram.svg")
</template>

<script>
export default {
  props: ["alturaTela", "larguraTela"],

  data: () => ({
    drawerAberto: false,
    auxiliar: "",
  }),

  methods: {
    fecharMenu() {
      this.drawerAberto = false;
      this.auxiliar = "slideUp";
    },
  },
};
</script>

<style lang="scss">
.visibility-hidden{
  visibility: hidden;
}
nav.navegacao-mobile {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  a {
    color: #000;
    text-decoration: none;
    font-weight: 300;
    font-size: 16px;
    padding: 0 10px;

    &.logo {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      img{
        width: 50px;
        height: 50px;
      }
    }
  }

  .pagina-ativa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 131px;
    height: 70px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 2px solid #000;


    img {
      width: 18px;
      margin-top: 2px;
      margin-left: 10px;
    }
  }

  .navegacao {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    flex-direction: column;
    height: 100%;
    visibility: hidden;
    z-index: -2;
    overflow: auto;



    &.slideUp {
      animation: slideUp .6s ease-in-out forwards;
    }

    @keyframes slideDown {
      0% {
        top: 100%;
      }
      100% {
        top: 0;
      }
    }

    @keyframes slideUp {
      0% {
        top: 0;
      }
      100% {
        top: 2000px;
        visibility: visible;
      }
    }

    &.slideDown {
      animation: slideDown 0.5s ease-in-out forwards;
      visibility: visible;

      .linha-topo-fechar {
        width: 100%;
        height: 80px;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          color: #fff;
          text-align: center;
          font-size: 22px;
          font-weight: 700;

        }
        img {
          cursor: pointer;
          width: 20px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(91%)
            saturate(0%) hue-rotate(298deg) brightness(103%) contrast(101%);
        }
        .fechar-menu{
        }
      }
    }

    .links-menus {
      margin-top: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .label-menu {
        width: 56px;
        height: 27px;
        border-radius: 100px;
        background: #1b1b1b;
        color: #717171;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        padding: 5px;
      }

      a {
        color: #fff;
        margin-bottom: 23px;
        font-size: 39px;
        font-weight: 700;
        text-align: center;

        &.router-link-exact-active {
          color: #2c2c2c;
        }
      }
    }

    .redes-sociais-mobile {
      width: 279px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 30px;
      box-sizing: border-box;
      margin: 50px 0 20px 0;

      a {
        line-height: 0;
        position: relative;
        width: 92px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          padding-left: 10px;
          margin-left: 10px;
        }

        img {
          filter: brightness(0) saturate(100%) invert(45%) sepia(7%)
            saturate(26%) hue-rotate(324deg) brightness(96%) contrast(84%);
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
@media (max-width: 913px) {
  nav.navegacao-mobile {
    .navegacao {
      .redes-sociais-mobile {
        margin-top: 60px;
      }
    }
    .pagina-ativa {
      margin-right: -5px;
    }
  }
}
@media (max-width: 835px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 70px;
    }
  }
}
@media (max-width: 821px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 20px;
      img{
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 770px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: -10px;
    }
  }
}
@media (max-width: 713px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 40px;
    }
  }
}
@media (max-width: 601px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 155px;
    }
  }
}
@media (max-width: 541px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: -15px;
    }
  }
}
@media (max-width: 531px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 0px;
    }
  }
}

@media (max-width: 517px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 10px;
    }
  }
}

@media (max-width: 511px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 15px;
      font-size: 22px;
    }
  }
}

@media (max-width: 486px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 45px;
    }
  }
}

@media (max-width: 470px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 50px;
    }
  }
}
@media (max-width: 460px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 70px;
    }
  }
}

@media (max-width: 431px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 95px;
    }
  }
}

@media (max-width: 415px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 110px;
    }
  }
}

@media (max-width: 413px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 115px;
    }
  }
}
@media (max-width: 394px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 130px;
    }
  }
}
@media (max-width: 391px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 135px;
    }
  }
}

@media (max-width: 376px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 150px;
    }
  }
}
@media (max-width: 360px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 160px;
    }
  }
}
@media (max-width: 321px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: 200px;
    }
    .pagina-ativa {
      width: 100px;
      .label {
        font-size: 13px;
      }
      img {
        margin-left: 5px;
        width: 12px;
      }
    }
  }
}
@media (max-width: 280px) {
  nav.navegacao-mobile {
    a.logo {
      margin-left: -15px;
      font-size: 18px;
    }
    .pagina-ativa {
      width: 100px;
      .label {
        font-size: 13px;
      }
      img {
        margin-left: 5px;
        width: 12px;
      }
    }
  }
}
// @media (max-width: 241px) {
//   nav.navegacao-mobile {
//     a.logo {
//       font-size: 18px;
//       margin-left: 40px;
//     }
//     .pagina-ativa {
//       padding: 0px;
//       width: 90px;
//       .label {
//         margin-left: 10px;
//         font-size: 12px;
//       }
//       img {
//         width: 10px;
//       }
//     }
//   }
// }
</style>
