<template lang="pug">
.container
  #container-logo
    .container-img
      img.logo(src="@/assets/home/sinapse-img.png")
    .descricao-logo {{ subtituloLogo }}
  .frase-marketing
    .frase-conteudo
    | Somos seu aliado na automação e termometria, moldando e implementando soluções personalizadas para o seu sucesso.
  //- h2.titulo-trabalhos 
  //-  | Produtos
  #tipos-de-trabalhos
    TiposDeTrabalhos(v-if="largura > 1024")
    TiposDeTrabalhosMobile(v-else)
  #carrossel(@scroll="scrollToSection('carrossel')")
    Produtos(v-if="largura > 1024")
    ProdutosMobile(v-else)

  #diferenciais(@scroll="scrollToSection('diferenciais')")
    Diferenciais
</template>

<script>
import Produtos from "@/components/home/Produtos.vue";
import ProdutosMobile from "@/components/home/ProdutosMobile.vue";
import Diferenciais from "@/components/home/Diferenciais.vue"
import TiposDeTrabalhos from "@/components/home/TiposDeTrabalhos.vue"
import TiposDeTrabalhosMobile from "@/components/home/TiposDeTrabalhosMobile.vue"
export default {
  components: {
    Produtos,
    ProdutosMobile,
    Diferenciais,
    TiposDeTrabalhos,
    TiposDeTrabalhosMobile
  },
  data: () => {
    return {
      subtituloLogo: "Soluções & Automação",
      largura: window.innerWidth,
    };
  },
  mounted() {
    this.scrollToHash();
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("orientationchange", this.handleWindowResize);
    // Atualizar o valor da largura quando o componente é montado
    this.updateWindowDimensions();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("orientationchange", this.handleWindowResize);
  },
  methods: {
    scrollToHash() {
      if (window.location.hash) {
        var hash = window.location.hash.substring(1); // Remove o símbolo '#'
        var element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    handleWindowResize() {
      this.updateWindowDimensions();
    },
    updateWindowDimensions() {
      this.largura = window.innerWidth;
      console.log("Screen Width: ", this.largura);
    }
  }
}
</script>

<style lang="scss">
.container {
  padding-top: 80px;
  font-size: 45px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;

  #container-logo {
    padding: 60px 0px;
    width: 70%;
    height: 100%;
    background: #fff;
    margin: -40px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container-img {
      width: 100%;
      margin: -25px auto;
      height: 100%;

      .logo {
        max-width: 100%;
      }
    }

    .descricao-logo {
      display: inline-block;
      width: 400px;
      margin: -140px 95px 0 auto;
      text-align: end;
      align-self: center;
      font-size: 25px;
    }
  }

  .frase-marketing {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    width: 900px;
    display: flex;
    justify-content: center;
    margin: 60px auto;

    .frase-conteudo {
      margin: 10px auto 20px auto;

      .automacao {
        color: #f47500;
      }

      .gradient {
        color: transparent;
        background: linear-gradient(90deg, rgb(6, 31, 255) 0%, rgb(5, 255, 0) 35%, rgb(255, 229, 0) 70%, rgb(255, 0, 0) 100%);
        -webkit-background-clip: text;
        background-clip: text;

      }
    }
  }

  .titulo-trabalhos {
    margin: 0 auto 40px auto;
  }

  #diferenciais {
    margin: 20px 0 40px 0;
  }

  #tipos-de-trabalhos {
    margin-bottom: 40px;
  }
}

@media (max-width:1025px) {
  .container {
    width: 1024px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -100px;
        margin-right: 70px;
      }
    }

    .frase-marketing {
      .frase-conteudo {}
    }
  }
}
@media (max-width:913px) {
  .container {
    width: 912px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -90px;
        margin-right: 65px;
      }
    }

    .frase-marketing {
      .frase-conteudo {}
    }
  }
}
@media (max-width:821px) {
  .container {
    width: 820px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -75px;
        margin-right: 60px;
      }
    }

    .frase-marketing {
      width: 700px;
      .frase-conteudo {}
    }
  }
}
@media (max-width:713px) {
  .container {
    width: 712px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -60px;
        margin-right: 50px;
        font-size: 20px;
      }
    }

    .frase-marketing {
      width: 700px;
      font-size: 30px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:601px) {
  .container {
    width: 600px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -40px;
        margin-right: 40px;
        font-size: 15px;
        width: 300px;
      }
    }

    .frase-marketing {
      width: 500px;
      font-size: 25px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:581px) {
  .container {
    width: 580px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -40px;
        margin-right: 40px;
        font-size: 15px;
        width: 300px;
      }
    }

    .frase-marketing {
      width: 500px;
      font-size: 25px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:461px) {
  .container {
    width: 460px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 40px;
        font-size: 15px;
        width: 300px;
      }
    }

    .frase-marketing {
      width: 400px;
      font-size: 25px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:481px) {
  .container {
    width: 480px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 40px;
        font-size: 15px;
        width: 300px;
      }
    }

    .frase-marketing {
      width: 400px;
      font-size: 25px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:431px) {
  .container {
    width: 430px;

    #container-logo {
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 30px;
        font-size: 15px;
        width: 250px;
      }
    }

    .frase-marketing {
      width: 400px;
      font-size: 25px;
      margin: 40px 7px;

      .frase-conteudo {}
    }
  }
}
@media (max-width:415px) {
  .container {
    width: 414px;

    #container-logo {
      padding: 60px 0 10px 0 ;
      .container-img{
        img.logo {
          margin-top: 60px;
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 30px;
        font-size: 15px;
        width: 250px;
      }
    }

    .frase-marketing {
      width: 400px;
      font-size: 20px;
      margin: 30px 7px;

      .frase-conteudo {}
    }
  }
}
@media (max-width: 395px) {
  .container {
    width: 394px;

    #container-logo {
      padding: 60px 0 10px 0 ;
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 30px;
        font-size: 15px;
        width: 250px;
      }
    }

    .frase-marketing {
      width: 350px;
      font-size: 20px;

      .frase-conteudo {}
    }
  }
}
@media (max-width: 376px) {
  .container {
    width: 375px;

    #container-logo {
      padding: 60px 0 10px 0 ;
      .container-img{
        img.logo {
          margin-top: 30px;
        }
      }

      .descricao-logo {
        margin-top: -30px;
        margin-right: 30px;
        font-size: 15px;
        width: 250px;
      }
    }

    .frase-marketing {
      width: 350px;
      font-size: 15px;

      .frase-conteudo {}
    }
  }
}
@media (max-width: 321px) {
  .container {
    width: 360px;

    #container-logo {
      padding: 60px 0 10px 0 ;
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -20px;
        margin-right: 70px;
        font-size: 13px;
        width: 230px;
      }
    }

    .frase-marketing {
      width: 300px;
      font-size: 14px;

      .frase-conteudo {}
    }
  }
}
@media (max-width: 281px) {
  .container {
    width: 280px;

    #container-logo {
      padding: 60px 0 10px 0 ;
      .container-img{
        img.logo {
        }
      }

      .descricao-logo {
        margin-top: -15px;
        margin-right: 70px;
        font-size: 10px;
        width: 180px;
      }
    }

    .frase-marketing {
      width: 270px;
      font-size: 12px;

      .frase-conteudo {}
    }
  }
}
</style>
