<template lang="pug">
.app
  .carousel
    .carousel-slide(:style="{ transform: 'translateX(' + -index * largura + 'px)' }")
      div.container(v-for="(item, i) in images" :key="i")
        .imagem(:style="`background-image: url(${item.img})`" :class="{ 'active': i === index }")
          .slide-2(v-if="i === 0")
            h2.title-slide-2 {{ images[0].title }} 
            p.slide-text-automacao {{  images[0].text }}
            router-link.buttonAutomcao(to="/automacao") {{ images[0].textoBotao }}

          .slide-1(v-if="i === 1")
            h2.title-slide {{ images[1].title }}
            p.slide-text-termometria {{ images[1].text }}
            router-link.buttonTermometria(to="/termometria") {{ images[1].textoBotao }}

    button.carousel-btn.prev-btn(@click="prevSlide")
      img.right-img(src="@/assets/home/chevron-down-white.svg")
    button.carousel-btn.next-btn(@click="nextSlide")
      img.left-img(src="@/assets/home/chevron-down-white.svg")
        
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      mouseDown: false,
      startX: 0,
      scrollLeft: 0,
      images: [
        { img: require('../../assets/home/automacao-2.jpg'), title: "Automação", text: "Otimize a automação para aumentar a eficácia no controle e monitoramento de processos industriais.", textoBotao: "Saiba mais" },
        {
          img: require('../../assets/home/termometria.jpg'),
          title: "Termometria", text: "Maximizando a eficiência no armazenamento de grãos: Monitoramento e Controle de Ponta",
          textoBotao: "Saiba mais"
        },
      ],
      intervalId: null,
      isTransition: false,
      largura: 0, // Largura inicial da tela
    };
  },
  methods: {
    prevSlide() {
      this.isTransition = true;
      this.clearIntervalo();
      this.index = (this.index - 1 + this.images.length) % this.images.length;
      this.startAutoSlide();
    },
    nextSlide() {
      this.isTransition = true;
      this.clearIntervalo();
      this.index = (this.index + 1) % this.images.length;
      this.startAutoSlide();
    },
    startAutoSlide() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },
    clearIntervalo() {
      clearInterval(this.intervalId);
    },
    makeAnimation() {
      setTimeout(() => {
        const slideAnimationClasses = [
          [".title-slide-2", ".slide-text-automacao", ".buttonAutomcao"],
          [".title-slide", ".slide-text-termometria", ".buttonTermometria"]
        ];
        const prevSlideElements = document.querySelectorAll(slideAnimationClasses[this.index === 0 ? 1 : 0].join(", "));
        prevSlideElements.forEach(element => {
          element.classList.remove("text-animation");
        });
        const currentSlideElements = document.querySelectorAll(slideAnimationClasses[this.index].join(", "));
        currentSlideElements.forEach(element => {
          element.classList.add("text-animation");
        });
      }, 50);
    },
    getCarouselWidth() {
      const carousel = document.querySelector('.carousel');
      if (carousel) {
        this.largura = carousel.offsetWidth; // Obtém a largura do elemento .carousel
      }
    },
    handleWindowResize() {
      this.getCarouselWidth(); // Chamado quando a janela é redimensionada
    },
  },
  mounted() {
    this.startAutoSlide();
    window.addEventListener("resize", this.handleWindowResize);
    this.getCarouselWidth(); // Chamado após a montagem do componente
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>
<style lang="scss">
.app {
  .carousel {
    width: 1440px;
    height: 600px;
    overflow: hidden;
    position: relative;
    object-fit: cover;
    margin: 0 auto 20px auto;
    cursor: pointer;

    .carousel-slide {
      display: flex;
      transition: transform 0.5s ease-out;
      justify-items: center;
      width: 100%;


      .imagem {
        position: relative;
        width: 1440px;
        height: auto;
        object-fit: cover;
        justify-self: center;
        bottom: 10px;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;

        // filter: brightness(0.9);
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); // Cor preta com 50% de opacidade
        }

        .slide-1 {
          width: 100%;
          height: 600px;
          text-align: center;
          padding: 90px 0;

          .title-slide {
            width: 100%;
            position: absolute;
            text-align: center;
            z-index: 2;
            text-align: center;
            color: transparent;
            -webkit-background-clip: text;
            background: #fff;
            background-clip: text;
            margin: 0px;
          }

          // Slide 1
          .slide-text-termometria {
            color: #fff;
            position: absolute;
            z-index: 2;
            text-align: center;
            font-size: 30px;
            display: flex;
            justify-content: center;
            right: 15%;
            left: 15%;
            margin: 9% 0px;
            font-weight: 600;
          }

          .buttonTermometria {
            position: absolute;
            max-width: 100%;
            text-align: center;
            font-size: 25px;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            right: 40%;
            left: 40%;
            background: #000;
            color: #fff;
            border: 2px solid transparent;
            font-weight: bold;
            text-decoration: none;
            margin: 20% 0px;

            &:hover {
              color: #000;
              background: #fff
            }
          }
        }

        .slide-2 {
          width: 100%;
          height: 600px;
          text-align: center;
          padding: 90px 0;
          position: relative;

          .title-slide-2 {
            position: absolute;
            color: #fff;
            text-align: center;
            z-index: 2;
            display: inline;
            text-align: center;
            left: 38%;
            margin: 0;
          }

          .slide-text-automacao {
            position: absolute;
            max-width: 100%;
            color: #fff;
            z-index: 2;
            text-align: center;
            font-size: 30px;
            display: flex;
            justify-content: center;
            font-weight: 600;
            right: 10%;
            left: 10%;
            margin: 9% 0px;
          }

          .buttonAutomcao {
            position: absolute;
            max-width: 100%;
            text-align: center;
            font-size: 25px;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            right: 40%;
            left: 40%;
            background: #000;
            color: #fff;
            border: 2px solid transparent;
            font-weight: bold;
            text-decoration: none;
            margin: 20% 0px;


            &:hover {
              background: #f47500;
              color: #ebff05;
            }
          }
        }

        // Animation text slide ===========================================================================================================
        // .text-animation {
        //   animation: slideDown 2s forwards;
        //   opacity: 0;
        // }

        // @keyframes slideDown {
        //   0% {
        //     opacity: 0;
        //     transform: translateY(100%);
        //   }

        //   100% {
        //     opacity: 1;
        //     transform: translateY(0);
        //   }
        // }
      }
    }

    .carousel-btn {
      position: absolute;
      top: 50%;
      // transform: translateY(-50%);
      cursor: pointer;
      padding: 10px;
      background: rgba(10, 10, 10, 0.5);
      color: #fff;
      border: none;
      outline: none;
      z-index: 1;

      img {
        width: 20px;
        padding: 0px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .prev-btn {
      left: 0;

      img {
        width: 30px;
        transform: rotate(90deg);
        filter: brightness(0.3);
        padding: 10px;

        &:hover {
          filter: brightness(1.0);
        }
      }
    }

    .next-btn {
      right: 0px;

      img {
        width: 30px;
        transform: rotate(-90deg);
        filter: brightness(0.3);
        padding: 10px;

        &:hover {
          filter: brightness(1.0);
        }
      }
    }
  }
}

// @media (max-width: 1537px) {
//   .app {
//     display: flex;
//     justify-content: center;
//     margin: 0 auto;
//     width: 1536px;

//     .carousel {
//       .carousel-slide {
//         .imagem {
//           width: 1536px;

//           .slide-1 {}
//         }
//       }
//     }
//   }
// }
@media (max-width: 1281px) {
  .app {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 1280px;

    .carousel {
      .carousel-slide {
        .imagem {
          width: 1280px;

          .slide-1 {}
        }
      }
    }
  }
}
</style>
