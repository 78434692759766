<template lang="pug">
#app
  .container-rotas
    header(:class="[true&&'header-mobile']")
      Navegacao(v-if="largura>1025")
      NavegacaoMobile(v-if="largura<1025" @atualizar-drawer="atualizarDrawer" )
    main
      router-view
    Footer
</template>

<script>
import Navegacao from "@/components/navegacao/Navegacao";
import NavegacaoMobile from "@/components/navegacao/NavegacaoMobile";
import Footer from "@/components/footer/Footer";

export default {
  components: {
    Navegacao,
    NavegacaoMobile,
    Footer,
  },
  data() {
    return {
      largura: window.innerWidth,
      altura: window.innerHeight,
      drawerAbertoApp: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  watch: {
    "$route.name"() {
      window.scrollTo({
        top: 0,
      });
    },
    drawerAbertoApp(novoEstado) {
    console.log('Novo estado do drawerAbertoApp:', novoEstado);
    if (!novoEstado) {
      document.body.classList.remove('out-scroll');
    } else {
      document.body.classList.add('out-scroll');
    }
  }
  },
  methods: {
    handleWindowResize() {
      this.largura = window.innerWidth;
    },
    atualizarDrawer(novoEstado) {
      this.drawerAbertoApp = novoEstado;
    },
  },
};
</script>

<style lang="scss">
/* Adicione seus estilos aqui */
</style>

<style lang="scss">
/* Adicione seus estilos aqui */
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap");

html * {
  font-family: "Lato", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}
.out-scroll{
  overflow: hidden;
}
#app {
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.container-rotas {
  width: 1440px;
  background: #fff;

  header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c8d2df;
    position: fixed;
    top: 0;
    width: 1440px;
    padding: 0 50px;
    box-sizing: border-box;
    background: #fff;
    z-index: 2;

    &.header-mobile {
      padding: 0 20px;
      transition: 1s;
    }
  }
}

@media (max-width: 1600px) {
  #app {
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent; /* Cor do polegar da barra de rolagem */
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* Cor da trilha da barra de rolagem */
    }
  }
}

@media (max-width: 1281px) {
  .container-rotas {
    header {
      width: 1280px;
      right: 0px;
    }
  }
}

@media (max-width: 1025px) {
  .container-rotas {
    header {
      width: 1024px;
      right: 0px;
      &.header-mobile {
        padding: 0px 20px 0px 10px;
      }
    }
  }
}

// MENU MOBILE
@media (max-width: 913px) {
  .container-rotas {
    header {
      width: 913px;
    }
  }
}

@media (max-width: 821px) {
  .container-rotas {
    header {
      right: 0px;
      width: 820px;

      &.header-mobile {
        padding: 0 20px;
      }
    }
  }
}
@media (max-width: 769px) {
  .container-rotas {
    header {
      right: 0px;
      width: 768px;

      &.header-mobile {
        padding: 0 20px;
      }
    }
  }
}

// MENU CELULAR
@media (max-width: 541px) {
  .container-rotas {
    header {
      width: 540px;
    }
  }
}

@media (max-width: 281px) {
  .container-rotas {
    header {
      width: 281px;
    }
  }
}
</style>
