<template lang="pug">
footer
  .conteudo-empresa
    .logo Sinapse
      img.registrado(src="@/assets/icons/registered.svg")
    .infos
      .cnpj(v-if="largura > 541") 
        |Todos os direitos reservados. Sinapse Soluções Ltda. CNPJ: 14.503.773/0001-03
      .cnpj(v-else)
        |Copyright © 2023 Sinapse. Todos os direitos reservados. Sinapse Soluções Ltda. CNPJ: 14.503.773/0001-03
      .endereco Rua Antônio Francisco de Almeida 145, Planalto, Campo Grande - MS, 79009-830, Brasil
      .links(v-if="largura > 541")
        a(href="#") Política de Privacidade<div>|</div>
        a(href="#") Política de Vendas<div>|</div>
        a(href="#") Avisos Legais<div>|</div>
      .links(v-else)
        a(href="#") Política de Privacidade
        a(href="#") Política de Vendas
        a(href="#") Avisos Legais
  .redes-sociais
    a(href="https://facebook.com/SinapseSolucoeseAutomacoes" target="_blank")
      img(src="@/assets/footer/facebook.svg")
    a(href="https://www.linkedin.com/company/sinapse-soluções-e-automação" target="_blank")
      img(src="@/assets/footer/linkedin-in.svg")
    a(href="http://instagram.com/sinapsesolucoes/" target="_blank")
      img(src="@/assets/footer/instagram.svg")
</template>

<script>
export default {
  data: () => ({
    largura: window.innerWidth,
    altura: window.innerHeight,
  }),
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  methods: {
    handleWindowResize() {
      this.largura = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
footer {
  height: 200px;
  background: #eeeeee;
  color: #000;
  display: flex;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;

  .conteudo-empresa {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: bold;

      img.registrado {
        width: 13px;
        height: 13px;
        margin-top: -18px;
      }
    }
    .infos {
      font-size: 12px;
      margin-left: 25px;
      width: 840px;

      .endereco {
        margin: 10px 0;
      }
      .links {
        display: flex;
        align-items: center;

        a {
          color: #000;
          text-decoration: none;
          margin-right: 5px;
          display: flex;
          align-items: center;

          & > div {
            padding-left: 5px;
          }
        }
      }
    }
  }
  .redes-sociais {
    width: 335px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 30px;
    box-sizing: border-box;

    a {
      line-height: 0;
      position: relative;

      span {
        padding-left: 10px;
        margin-left: 10px;
      }

      img {
        filter: brightness(0) saturate(100%) invert(45%) sepia(7%) saturate(26%)
          hue-rotate(324deg) brightness(96%) contrast(84%);
        width: 28px;
        height: 28px;
      }
    }
  }
}

@media (max-width: 1280px) {
  footer {
    width: 100%;
    .conteudo-empresa {
      margin: 60px;
    }
  }
}

@media (max-width: 1024px) {
  footer {
    justify-items: center;
    flex-direction: column;
    height: 251px;
    text-align: center;

    .conteudo-empresa {
      display: flex;
      gap: 10px;
      text-align: center;
      flex-direction: column;
      margin: 0px;
      .logo {
        display: block;
        align-content: center;
        margin: 20px 0px 10px 0px;
      }
      .infos {
        margin-left: 0px;
        .links {
          justify-content: center;
        }
      }
    }

    .redes-sociais {
      margin: 40px 0px 50px 0px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    display: grid;
    justify-content: center;
    gap: 10px;
    justify-items: center;
    height: 251px;

    .conteudo-empresa {
      .logo {
        display: block;
        align-content: center;
        margin: 20px 0px 10px 0px;
      }
      .infos {
        .links {
          justify-content: center;
        }
      }
    }

    .redes-sociais {
      margin: 40px 0px 50px 0px;
    }
  }
}
@media (max-width: 541px) {
  footer {
    display: flex;
    flex-direction: column;
    height: 400px;
    .conteudo-empresa {
      .logo {
        font-size: 22px;
      }
      .infos {
        width: 400px;
        .links {
          display: flex;
          flex-direction: column;
          a {
            border-bottom: 1px solid #000;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 376px) {
  footer {
    display: flex;
    flex-direction: column;
    height: 375px;
    .conteudo-empresa {
      .logo {
        font-size: 22px;
      }
      .infos {
        width: 375px;
        .links {
          display: flex;
          flex-direction: column;
          a {
            border-bottom: 1px solid #000;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 361px) {
  footer {
    display: flex;
    flex-direction: column;
    height: 360px;
    .conteudo-empresa {
      .logo {
        font-size: 22px;
      }
      .infos {
        width: 360px;
        .links {
          display: flex;
          flex-direction: column;
          a {
            border-bottom: 1px solid #000;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 321px) {
  footer {
    display: flex;
    flex-direction: column;
    height: 360px;
    .conteudo-empresa {
      .logo {
        font-size: 22px;
      }
      .infos {
        width: 320px;
        .links {
          display: flex;
          flex-direction: column;
          a {
            border-bottom: 1px solid #000;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 281px) {
  footer {
    display: flex;
    flex-direction: column;
    .conteudo-empresa {
      .logo {
        font-size: 22px;
      }
      .infos {
        width: 280px;
        .links {
          display: flex;
          flex-direction: column;
          a {
            border-bottom: 1px solid #000;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
</style>
