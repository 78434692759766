<template lang="pug">
.diferenciais-container
  h2.titulo Diferenciais
  .container-diferencas
    .primerias-diferencas
      .diferencas(v-for="diferenca,i in diferencas")
        h4.titulo-diferencas {{ diferenca.title }}
        p.texto {{  diferenca.text }}
    .segundas-diferencas
      .diferencas(v-for="diferenca,i in outrasDiferencas")
        h4.titulo-diferencas {{ diferenca.title }}
        p.texto {{  diferenca.text }}

</template>

<script>
export default {
  data() {
    return {
      "diferencas": [
        { "title": "Adaptável", "text": "A adaptabilidade é crucial na automação e termometria, onde a Sinapse se destaca. Em um ambiente tecnológico em constante evolução, a Sinapse ajusta-se rapidamente às demandas do mercado e inovações." },
        { "title": "Profissionais capacitados", "text": "Temos uma equipe de profissionais altamente qualificados, incluindo eletricistas e programadores, que formam a base sólida da excelência em automação. Com expertise técnica e criatividade, nossa equipe diversificada demonstra um compromisso inabalável." },
        { "title": "Assistência rápida", "text": "Na Assistência Rápida, profissionais de ponta formam a espinha dorsal da excelência em serviços de suporte e assistência técnica." }
      ],
      "outrasDiferencas": [
        { "title": "Eficiente", "text": "Os colaboradores garantem a produção e montagem de dispositivos de alta qualidade, enquanto os engenheiros lideram o desenvolvimento de soluções inovadoras. Os programadores criam sistemas personalizados, adaptados às necessidades individuais de cada cliente." },
        { "title": "Qualidade no produto", "text": "Cada dispositivo e sistema é cuidadosamente projetado e fabricado para atender aos mais altos padrões de excelência. Desde a concepção até a entrega, priorizamos cada detalhe para garantir que nossos clientes recebam produtos confiáveis e duráveis." },
        { "title": "Personalizados", "text": "Desenvolvidos com um foco singular nas necessidades individuais de cada cliente, nossa linha de produtos oferece soluções sob medida para uma variedade de aplicações." }
      ]

    }
  }
}
</script>

<style lang="scss">
.diferenciais-container {
  .titulo {
    text-align: center;
    margin: 50px auto 50px auto;
  }

  .container-diferencas {
    width: 1000px;
    display: grid;
    grid-template-columns: 500px 500px;
    align-items: center;
    justify-content: center;

    .primerias-diferencas {
      padding: 15px;

      .diferencas {
        width: 500px;
        font-size: 40px;

        .texto {
          margin-right: 50px;
        }
      }
    }

    .segundas-diferencas {
      border-left: 3px solid #000;
      padding: 35px;

      .diferencas {
        width: 500px;
        font-size: 40px;
      }
    }

    .diferencas {
      .titulo-diferencas {
        margin: 0;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background: #000;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          left: -4%;
          top: 45%;
        }
      }

      .texto {
        margin: 10px 0 0 0;
        font-size: 20px;
        text-align: start;
      }
    }
  }

  .borda-left {
    &::before {
      content: "";
      border-left: 2px solid #000;
    }
  }
}

@media(max-width: 1025px) {
  .diferenciais-container {
    h2.titulo {}

    .container-diferencas {
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
}
@media(max-width: 601px) {
  .diferenciais-container {
    width: 600px;
    h2.titulo {
      font-size: 60px;
    }

    .container-diferencas {
      width: 600px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
}
@media(max-width: 541px) {
  .diferenciais-container {
    width: 540px;
    margin-top: -80px;
    h2.titulo {
      font-size: 60px;
    }

    .container-diferencas {
      width: 540px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
}
@media(max-width: 481px) {
  .diferenciais-container {
    width: 480px;
    h2.titulo {
      font-size: 50px;
    }

    .container-diferencas {
      width: 480px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 480px;
            margin: 10px auto;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 430px;

          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 100%;
            margin: 10px auto;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 430px;
          }
        }
      }
    }
  }
}
@media(max-width: 431px) {
  .diferenciais-container {
    margin-top: -150px;
    width: 430px;
    h2.titulo {
      font-size: 45px;
    }

    .container-diferencas {
      width: 430px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 430px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 400px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 430px;


            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 400px;
          }
        }
      }
    }
  }
}
@media(max-width: 415px) {
  .diferenciais-container {
    width: 414px;
    h2.titulo {
      font-size: 45px;
    }

    .container-diferencas {
      width: 414px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 414px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 400px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 414px;


            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 400px;
          }
        }
      }
    }
  }
}
@media(max-width: 395px) {
  .diferenciais-container {
    width: 394px;
    h2.titulo {
      font-size: 45px;
    }

    .container-diferencas {
      width: 394px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 394px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 360px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 394px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 360px;
          }
        }
      }
    }
  }
}
@media(max-width: 376px) {
  .diferenciais-container {
    width: 375px;
    margin-top: -100px;
    h2.titulo {
      font-size: 40px;
      margin: -80px  0 40px 0 ;
    }

    .container-diferencas {
      width: 375px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 375px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 340px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 375px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 340px;
          }
        }
      }
    }
  }
}
@media(max-width: 361px) {
  .diferenciais-container {
    width: 360px;
    h2.titulo {
      font-size: 40px;
      margin: -80px  0 40px 0 ;
    }

    .container-diferencas {
      width: 360px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 360px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 340px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 360px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 340px;
          }
        }
      }
    }
  }
}
@media(max-width: 321px) {
  .diferenciais-container {
    width: 320px;
    h2.titulo {
      font-size: 35px;
      margin: -80px  0 40px 0 ;
    }

    .container-diferencas {
      width: 320px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 320px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 300px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 320px;
            font-size: 30px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 300px;
          }
        }
      }
    }
  }
}
@media(max-width: 281px) {
  .diferenciais-container {
    width: 280px;
    margin-top: -150px;
    h2.titulo {
      font-size: 35px;
      margin: -80px  0 40px 0 ;
    }

    .container-diferencas {
      width: 280px;
      display: flex;
      flex-direction: column;

      .primerias-diferencas {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 280px;
            font-size: 25px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 260px;
          }
        }
      }

      .segundas-diferencas {
        border-left: transparent;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px;
        .diferencas {
          text-align: center;

          h4.titulo-diferencas {
            width: 280px;
            font-size: 25px;

            &::before {
              display: none;
            }
          }

          p.texto {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            width: 260px;
          }
        }
      }
    }
  }
}
</style>
