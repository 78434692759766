<template lang="pug">
.container-trabalho 
    h2.titulo Projetos
    .vertentes-de-trabalho 
      .content(v-for="(item,index) in vertentens" :key="index" :class="index % 2 === 0 ? 'left' : 'right'" :data-tile="paths[index].title")
        //- .imagem-container(:style="{ backgroundImage: 'url(' + item.background + ')' }")
        //-   .saiba-mais(v-for="item,indexPath in paths" :key="indexPath") 
        //-     h6.title-saiba-mais {{ item.title  }} 
        //-     router-link.text-saiba-mais(to="`${item.path}`") Saiba mais
        .caixa
          .conteudo-caixa
            .imagem-container(:style="{ backgroundImage: 'url(' + item.background + ')' }")
            .saiba-mais
              //- .container-img
              //-   img(src="@/assets/home/logo_sinapse.png")
              //- .title-saiba-mais {{ paths[index].title }} 
              router-link.btn-link-saiba-mais(:to="paths[index].path") Saiba mais
        
</template>

<script>
export default {
  data() {
    return {
      vertentens: [
        {
          titulo: "Elétrica", background: require("../../assets/home/instalacao.jpg"),
        },
        {
          titulo: "Irrigação", background: require("../../assets/home/infraestrutura.jpg"),
        },
        // {
        //   titulo: "Infraestrutura", background: require("../../assets/home/irrigacao.png"),
        // },
        {
          titulo: "Automação", background: require("../../assets/home/automacao.png"),
        },
      ],
      paths: [
        {path: "/eletrica", title: "Elétrica"},
        {path: "/infraestrutura",title: "Infraestrutura"},
        // {path: "/eficienciaenergetica",title: "Eficiência Energética"},
        {path: "/automacao",title: "Automação 4.0"},
      ],
    };
  }
}
</script>

<style lang="scss">
.container-trabalho {
  margin: 60px 0 90px 0;
  .titulo{
    text-align: center;
  }
  .vertentes-de-trabalho {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
    .content{
      display: flex;
      box-sizing: border-box;
      position: relative;
      .caixa{
        position: relative;
        width: 250px;
        height: 250px;
        background: rgb(0, 0, 0);
        border-radius: 50%;
        overflow: hidden;
        &:before{
          content:"";
          position: absolute;
          // width: 100%;
          // height: 100%;
          inset: -10px 80px;
          background: linear-gradient(315deg, #2170e6, #08c784);
          animation: girar 3s linear infinite;
          transition: .5s;
        }
        @keyframes girar {
          0%{
            transform: rotate(0deg);
          }
          100%{
            transform: rotate(360deg);
          }
        }
        &:hover::before{
          inset: 0;
        }
        &:after{
          content:"";
          position: absolute;
          inset: 6px;
          background: #fff;
          border-radius: 50%;
        }
        .conteudo-caixa{
          position: absolute;
          border: 6px solid #222121;
          inset: 15px;
          z-index: 1;
          border-radius: 50%;
          .imagem-container{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat:no-repeat ;
            object-fit: cover;
            background-position-x: 65%;
            border-radius: 50%;
            position: absolute;
            // margin: 0 0 70px 0;
            justify-items: center;
            transition: transform 0.3s ease-in; /* Adicionando transição de transformação */
            transition: .5s;
            z-index: 1;


            &:hover ~ .saiba-mais{
              z-index: 2;
            }
          }
          .saiba-mais{
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            position: relative;
            top: 50%;
            row-gap: 10px;

            // .container-img{
            //   background: #fff;
            //   border-radius: 50%;
            //   width: 100px;
            //   height: 90px;
            //   display: flex;
            //   justify-items: center;
            //   align-items: center;
            //   opacity: 0.8;
            //   pointer-events: none;


            //   img{
            //   width: 70px;
            //   height: 70px;
            //   margin: 0 auto;
            //   pointer-events: none;
            //   }
            // }
           .title-saiba-mais{
              margin: 0;
              text-align: center;
              font-size: 18px;
              font-weight: 400;
              margin-bottom: 5px;
              color: #fff;
           }
           .btn-link-saiba-mais{
              font-size: 16px;
              cursor: pointer;
              text-decoration: none;
              // background: #fff;
              color: #fff;
              border: 2px solid #fff;
              padding: 8px;
              border-radius: 10px;
              transition: .4s;

              &:hover{
                background: #fff;
                color: #000;
                letter-spacing: 1px;
                transition: .2s;
              }
            }
            &:hover{
              z-index: 1;

            }
          }
        }
        &:hover .conteudo-caixa .imagem-container {
          filter: brightness(0.4);
        }
        &:hover{
          transition: .5s;
          transform: scale(1.1);
        }
      }


      // .imagem-container{
      //   width: 250px;
      //   height: 250px;
      //   background-size: cover;
      //   background-repeat:no-repeat ;
      //   object-fit: contain;
      //   background-position-x: 65%;
      //   border-radius: 50%;
      //   position: relative;
      //   margin: 0 0 70px 0;
      //   justify-items: center;
      //   transition: transform 0.3s ease-in; /* Adicionando transição de transformação */


      //   &:hover {
      //     transform: scale(1.2); /* Aumentando o tamanho em 20% */
      //   }
      //   .title-saiba-mais{
      //     margin: 0;
      //     color: #fff;
      //   }
      //   .saiba-mais{
      //     opacity: 0;
      //     transition: opacity 0.2s ease-in;
      //     content: "";
      //     width: 100%;
      //     height: 100%;
      //     // left: 2px;
      //     // top: 2px;
      //     position: absolute;
      //     background: rgba($color: (#000000), $alpha: 0.3);
      //     border-radius: 50%;
      //     z-index: 1;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     flex-direction: column;

      //     &:hover{
      //       opacity: 1;
      //     }
      //   &:hover::before {
      //   content: "";
      //     width: calc(100% - 4px);
      //     height: calc(100% - 4px);
      //     border: 2px solid #000; /* Adiciona uma borda branca */
      //     border-radius: 50%; /* Para criar uma borda circular */
      //     animation: girar 3s linear infinite; /* Aplica a animação de rotação */
      //     position: absolute;
      // }

      //   @keyframes girar {
      //       0%{
      //         transform: rotate(0deg);
      //       }
      //       100%{
      //         transform: rotate(360deg);
      //       }
      //     }
      //   }
      //   .text-saiba-mais{
      //     font-size: 20px;
      //     cursor: pointer;
      //     z-index: 1;
      //     text-decoration: none;
      //     color: #fff;
      //     border: 1px solid #fff;
      //     padding: 10px;
      //     border-radius: 10px;

      //     &:hover{
      //       background: #000;
      //       color: #fff;
      //     }
      //   }


      //   .titulo-conteudo{
      //     position: absolute;
      //     bottom: -20%;
      //     width: 100%;
      //     margin: 0 auto;
      //     text-align: center;
      //   }
      // }
      &::after{
              content: attr(data-tile);
              position: absolute;
              font-size: 25px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-content: center;
              bottom: -30%;
              color: #000;
              padding: 25px;
              left: -10%;
            }
            &:hover{

              transform:scale(1.1) ;
              transition: .5s;
            }

    }
  }

}
</style>
