<template lang="pug">
.container-trabalho-mobile 
  h2.titulo Projetos
  .vertentes-de-trabalho 
    .content(v-for="(item,index) in vertentens" :key="index" :class="index % 2 === 0 ? 'left' : 'right'" :data-tile="paths[index].title")
      //- .imagem-container(:style="{ backgroundImage: 'url(' + item.background + ')' }")
      //-   .saiba-mais(v-for="item,indexPath in paths" :key="indexPath") 
      //-     h6.title-saiba-mais {{ item.title  }} 
      //-     router-link.text-saiba-mais(to="`${item.path}`") Saiba mais
      router-link.caixa(:to="item.route")
        .conteudo-caixa
          .imagem-container(:style="{ backgroundImage: 'url(' + item.background + ')' }")
        
</template>

<script>
export default {
  data() {
    return {
      vertentens: [
        {
          titulo: "Elétrica", background: require("../../assets/home/instalacao.jpg"),route: "/eletrica"
        },
        {
          titulo: "Irrigação", background: require("../../assets/home/infraestrutura.jpg"),route: "/infraestrutura"
        },
        // {
        //   titulo: "Infraestrutura", background: require("../../assets/home/irrigacao.png"),route: "/infraestrutura"
        // },
        {
          titulo: "Automação", background: require("../../assets/home/automacao.png"),route: "/automacao"
        },
      ],
      paths: [
        { path: "/eletrica", title: "Elétrica" },
        { path: "/infraestrutura", title: "Infraestrutura" },
        // { path: "/eficienciaenergetica", title: "Eficiência Energética" },
        { path: "/automacao", title: "Automação" },
      ],
    };
  }
}
</script>

<style lang="scss">
.container-trabalho-mobile {
  margin: 60px 0 90px 0;

  .titulo {
    text-align: center;
  }

  .vertentes-de-trabalho {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;

    .content {
      display: flex;
      box-sizing: border-box;
      position: relative;

      .caixa {
        position: relative;
        width: 250px;
        height: 250px;
        background: rgb(0, 0, 0);
        border-radius: 50%;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          // width: 100%;
          // height: 100%;
          inset: -10px 80px;
          background: linear-gradient(315deg, #2170e6, #08c784);
          animation: girar 3s linear infinite;
          transition: .5s;
        }

        @keyframes girar {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        &:after {
          content: "";
          position: absolute;
          inset: 6px;
          background: #fff;
          border-radius: 50%;
        }

        .conteudo-caixa {
          position: absolute;
          border: 6px solid #222121;
          inset: 15px;
          z-index: 1;
          border-radius: 50%;

          .imagem-container {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            object-fit: cover;
            background-position-x: 65%;
            border-radius: 50%;
            position: absolute;
            // margin: 0 0 70px 0;
            justify-items: center;
            transition: transform 0.3s ease-in;
            /* Adicionando transição de transformação */
            transition: .5s;
            z-index: 1;

            &:hover~.saiba-mais {
              z-index: 2;
            }
          }

          .saiba-mais {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            position: relative;
            top: 65%;
            row-gap: 10px;

            .title-saiba-mais {
              margin: 0;
              text-align: center;
              font-size: 18px;
              font-weight: 400;
              margin-bottom: 5px;
              color: #fff;
            }

            .btn-link-saiba-mais {
              font-size: 16px;
              cursor: pointer;
              text-decoration: none;
              color: #fff;
              background: rgba(0, 0, 0, 0.5);
              border: 2px solid #fff;
              padding: 8px;
              border-radius: 10px;
              transition: .4s;
              z-index: 2;
              font-weight: bold;

              // &:hover {
              //   background: #fff;
              //   color: #000;
              //   letter-spacing: 1px;
              //   transition: .2s;
              // }
            }
          }
        }

        // &:hover .conteudo-caixa .imagem-container {
        //   filter: brightness(0.4);
        // }
      }

      &::after {
        content: attr(data-tile);
        position: absolute;
        font-size: 25px;
        width: 100%;
        // display: flex;
        // justify-content: center;
        // align-content: center;
        text-align: center;
        bottom: -30%;
        color: #000;
        padding: 35px;
        left: -10%;
      }
    }
  }
}
@media(max-width: 541px) {
  .container-trabalho-mobile{
    width: 540px;
    h2.titulo{
      font-size: 50px;
    }
    .vertentes-de-trabalho{
      .content{
        width: 200px;
        height: 200px;
        margin: 10px;
        &::after{
          margin-bottom: -20px ;
          font-size: 20px;
          padding-left: 20px;
        }
        .caixa{
          width: 200px;
          height: 200px;
          .conteudo-caixa{
            .imagem-container{

            }
            .saiba-mais{
                padding: 4px;
                color: #f00;
                font-size: 12px;
              router-link.btn-link-saiba-mais{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 481px) {
  .container-trabalho-mobile{
    width: 480px;
    h2.titulo{
      font-size: 45px;
    }
    .vertentes-de-trabalho{
      .content{
        width: 200px;
        height: 200px;
        margin: 10px;
        &::after{
          margin-bottom: -20px ;
          font-size: 20px;
          padding-left: 20px;
        }
        .caixa{
          width: 200px;
          height: 200px;
          .conteudo-caixa{
            .imagem-container{

            }
            .saiba-mais{
                padding: 4px;
                color: #f00;
                font-size: 12px;
              router-link.btn-link-saiba-mais{

              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 394px) {
  .container-trabalho-mobile{
    h2.titulo{
      font-size: 40px;
    }
  }
}
@media(max-width: 360px) {
  .container-trabalho-mobile{
    h2.titulo{
      font-size: 35px;
    }
  }
}
@media(max-width: 280px) {
  .container-trabalho-mobile{
    h2.titulo{
      font-size: 30px;
    }
  }
}
</style>
