<template lang="pug">
.container-home
  ExposicaoMarca
  .detalhe(v-if="largura > 1024" )
    a.link(:href="mandarParaWhats()")
      img(src="@/assets/home/whatsapp.png" alt="img")
      p Solicite Orçamento
  .detalhe(v-if="largura < 1024")
    a.link( :href="mandarParaWhats()")
      img(src="@/assets/home/whatsapp.png" alt="img")
</template>

<script>
import ExposicaoMarca from '@/components/home/ExposicaoMarca.vue';
export default {
  components: {
    ExposicaoMarca
  },
  data() {
    return {
      subtituloLogo: "Soluções & Automação",
      largura: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("orientationchange", this.handleWindowResize);
    // Atualizar o valor da largura quando o componente é montado
    this.updateWindowDimensions();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("orientationchange", this.handleWindowResize);
  },
  methods: {
    getPeriodo() {
      const date = new Date();
      let horaAtual = date.getHours();
      let msg = "";
      if (horaAtual > 5 && horaAtual < 13) {
        msg = "Bom dia";
      }
      else if (horaAtual > 13 && horaAtual < 18) {
        msg = "Boa tarde";
      } else if (horaAtual > 18 && horaAtual < 24) {
        msg = "Boa noite"
      }
      return msg;
    },
    mandarParaWhats() {
      let comprimento = this.getPeriodo();
      const numeroTelefone = '5567999349632';
      const mensagem = `${comprimento}, gostaria de solicitar um orçamento agora!!`; // corrigido para usar comprimento
      const link = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
      return link;
    },
    handleWindowResize() {
      this.updateWindowDimensions();
    },
    updateWindowDimensions() {
      this.largura = window.innerWidth;
    }
  }
}
</script>
<style lang="scss">
.container-home {
  font-size: 45px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .detalhe {
    width: 55px;
    height: 65px;
    position: fixed;
    right: 0;
    bottom: 25%;
    background: #2ca41c;
    opacity: 0.8;
    z-index: 2;
    transition-duration: 0.6s;
    border-radius: 50px 0 0 50px;
    box-sizing: border-box;
    animation: piscar 0.4s infinite alternate;

    a.link {
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        max-width: 50px;
        max-height: 50px;
        margin: auto 0;
        padding: 5px;

        &:hover~p {
          opacity: 1;
        }
      }

      p {
        box-sizing: border-box;
        font-size: 12px;
        width: 130px;
        display: inline;
        font-weight: 600;
        color: #fff;
        align-self: center;
        opacity: 0;
        transition-duration: 0.1s;
        position: absolute;
        left: 50px;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        p {
          opacity: 1;
        }
      }
    }

    &:hover {
      width: 170px;
      transition-duration: 0.3s;
      opacity: 1;
    }
    @keyframes piscar {
      from {
        opacity: 0.7;
      }
      to {
        opacity: 1;
      }
    }
  }
}

@media(max-width:1025px) {
  .container-home {
    .detalhe {
      width: 50px !important;
      height: 50px;
      position: fixed;
      left: 20px;
      bottom: 5%;
      background: #2ca41c;
      z-index: 1;
      border-radius: 50%;
      box-sizing: border-box;
      a.link {
        img {
          max-width: 50px;
          max-height: 50px;
          padding: 0;
        }
      }
    }
  }
}
@media(max-width:376px) {
  .container-home {
    .detalhe {
      width: 40px !important;
      height: 40px;
      position: fixed;
      left: 10px;
      bottom: 5%;
      background: #2ca41c;
      z-index: 1;
      border-radius: 50%;
      box-sizing: border-box;
      a.link {
        img {
          max-width: 40px;
          max-height: 40px;
          padding: 0;
        }
      }
    }
  }
}
@media(max-width:321px) {
  .container-home {
    .detalhe {
      width: 30px !important;
      height: 30px;
      position: fixed;
      left: 10px;
      bottom: 5%;
      background: #2ca41c;
      z-index: 2;
      border-radius: 50%;
      box-sizing: border-box;
      a.link {
        img {
          max-width: 30px;
          max-height: 30px;
          padding: 0;
        }
      }
    }
  }
}
</style>
