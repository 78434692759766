<template lang="pug">
.app-mobile
  .carousel
    .carousel-slide(:style="{ transform: 'translateX(' + -index * largura + 'px)' }")
      div.container(v-for="(item, i) in images" :key="i" class="slide-container" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd")
        .imagem(:style="`background-image: url(${item.img})`" :class="{ 'active': i === index }")
          .slide-2(v-if="i === 0")
            h2.title-slide-2 {{ images[0].title }} 
            p.slide-text-automacao {{  images[0].text }}
            a.buttonAutomcao(href="https://www.sinapsesolucoes.com/automacao") {{ images[0].textoBotao }}

          .slide-1(v-if="i === 1")
            h2.title-slide {{ images[1].title }}
            p.slide-text-termometria {{ images[1].text }}
            a.buttonTermometria(href="https://www.sinapsesolucoes.com/") {{ images[1].textoBotao }}

        
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      mouseDown: false,
      startX: 0,
      scrollLeft: 0,
      images: [
        { img: require('../../assets/home/automacao-2.jpg'), title: "Automação", text: "Otimize a automação para aumentar a eficácia no controle e monitoramento de processos industriais.", textoBotao: "Saiba mais" },
        {
          img: require('../../assets/home/termometria.jpg'),
          title: "Termometria", text: "Maximizando a eficiência no armazenamento de grãos: Monitoramento e Controle de Ponta",
          textoBotao: "Saiba mais"
        },

      ],
      intervalId: null,
      // screenWidth: 1024,
      isTransition: false,
      touchStartX: 0,
      touchEndX: 0,
      threshold: 100, // Limite para considerar um deslize
      largura: window.innerWidth, // Largura inicial da tela
    };
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.changedTouches[0].screenX;
    },
    handleTouchEnd() {
      const difference = this.touchStartX - this.touchEndX;
      if (Math.abs(difference) > this.threshold) {
        if (difference > 0) {
          // Deslize para a esquerda, avançar para o próximo slide
          this.nextSlide();
        } else {
          // Deslize para a direita, voltar para o slide anterior
          this.previousSlide();
        }
      }
    },

    previousSlide() {
      this.isTransition = true
      this.clearIntervalo();

      this.index = (this.index - 1 + this.images.length) % this.images.length;
      // this.index++;
      this.startAutoSlide();
    },
    nextSlide() {
      this.makeAnimation()
      setTimeout(() => {

      })
      this.clearIntervalo();
      // this.index++;
      if (this.index === this.images.length - 1) {
        this.index = 0;
      } else {
        this.index = (this.index + 1) % this.images.length;
      }
      this.startAutoSlide();
    },
    startAutoSlide() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },
    clearIntervalo() {
      clearInterval(this.intervalId);
    },
    // getScreenWidth() {
    //   const screenWidth = window.innerWidth;
    //   if(screenWidth == 1024){
    //     this.screenWidth = 1024
    //   }else if (screenWidth == 912) {
    //     this.screenWidth = 912;
    //   }else if (screenWidth == 820) {
    //     this.screenWidth = 820;
    //   }else if (screenWidth == 712) {
    //     this.screenWidth = 712;
    //   }else if (screenWidth == 600) {
    //     this.screenWidth = 600;
    //   }else if (screenWidth == 540) {
    //     this.screenWidth = 540;
    //   }else if (screenWidth == 480) {
    //     this.screenWidth = 480;
    //   }else if (screenWidth == 430) {
    //     this.screenWidth = 430;
    //   }else if (screenWidth == 414) {
    //     this.screenWidth = 414;
    //   }else if (screenWidth == 394) {
    //     this.screenWidth = 394;
    //   }else if (screenWidth == 375) {
    //     this.screenWidth = 375;
    //   }else if (screenWidth == 360) {
    //     this.screenWidth = 360;
    //   }else if (screenWidth == 320) {
    //     this.screenWidth = 320;
    //   }else if (screenWidth == 280) {
    //     this.screenWidth = 280;
    //   }
    //   else {
    //     this.screenWidth = screenWidth;
    //   }
    // },
    makeAnimation() {
      setTimeout(() => {
        const slideAnimationClasses = [
          [".title-slide-2", ".slide-text-automacao", ".buttonAutomcao"],
          [".title-slide", ".slide-text-termometria", ".buttonTermometria"]
        ];
        const prevSlideElements = document.querySelectorAll(slideAnimationClasses[this.index === 0 ? 1 : 0].join(", "));
        prevSlideElements.forEach(element => {
          element.classList.remove("text-animation");
        });
        const currentSlideElements = document.querySelectorAll(slideAnimationClasses[this.index].join(", "));
        currentSlideElements.forEach(element => {
          element.classList.add("text-animation");
        });
      }, 50);
    },
    getScreenWidth() {
      this.largura = window.innerWidth; // Atualiza a largura da tela
      console.log(this.largura)
    },
    handleWindowResize() {
      this.getScreenWidth(); // Chamado quando a janela é redimensionada
    },

  },
  mounted() {
    this.startAutoSlide();
    // this.getScreenWidth();
    // window.addEventListener("load", this.getScreenWidth);
    // window.addEventListener("resize", this.getScreenWidth())

    window.addEventListener("resize", this.handleWindowResize); // Adiciona event listener para redimensionamento da janela
    this.getScreenWidth(); // Define a largura da tela inicialmente
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>
<style lang="scss">
.app-mobile {
  .carousel {
    width: 1024px;
    height: 600px;
    overflow: hidden;
    position: relative;
    object-fit: cover;
    margin: 0 auto 20px auto;
    cursor: pointer;

    .carousel-slide {
      display: flex;
      transition: transform 0.5s ease-out;
      justify-items: center;
      width: 100%;


      .imagem {
        position: relative;
        width: 1024px;
        height: auto;
        object-fit: cover;
        justify-self: center;
        bottom: 10px;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;

        // filter: brightness(0.9);
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); // Cor preta com 50% de opacidade
        }

        .slide-1 {
          width: 100%;
          height: 600px;
          text-align: center;
          padding: 90px 0;

          .title-slide {
            width: 100%;
            position: absolute;
            text-align: center;
            z-index: 2;
            text-align: center;
            color: transparent;
            -webkit-background-clip: text;
            background: #fff;
            background-clip: text;
            margin: 0px;
          }

          // Slide 1
          .slide-text-termometria {
            color: #fff;
            position: absolute;
            z-index: 2;
            text-align: center;
            font-size: 30px;
            display: flex;
            justify-content: center;
            right: 15%;
            left: 15%;
            margin: 9% 0px;
            font-weight: 600;
          }

          .buttonTermometria {
            position: absolute;
            max-width: 100%;
            text-align: center;
            font-size: 25px;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            right: 40%;
            left: 40%;
            background: #000;
            color: #fff;
            border: 2px solid transparent;
            font-weight: bold;
            text-decoration: none;
            margin: 20% 0px;

            &:hover {
              color: #000;
              background: #fff
            }
          }
        }

        .slide-2 {
          width: 100%;
          height: 600px;
          text-align: center;
          padding: 90px 0;
          position: relative;

          .title-slide-2 {
            position: absolute;
            color: #fff;
            text-align: center;
            z-index: 2;
            display: inline;
            text-align: center;
            left: 38%;
            margin: 0;
          }

          .slide-text-automacao {
            position: absolute;
            max-width: 100%;
            color: #fff;
            z-index: 2;
            text-align: center;
            font-size: 30px;
            display: flex;
            justify-content: center;
            font-weight: 600;
            right: 10%;
            left: 10%;
            margin: 9% 0px;
          }

          .buttonAutomcao {
            position: absolute;
            max-width: 100%;
            text-align: center;
            font-size: 25px;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            right: 40%;
            left: 40%;
            background: #000;
            color: #fff;
            border: 2px solid transparent;
            font-weight: bold;
            text-decoration: none;
            margin: 20% 0px;


            &:hover {
              background: #f47500;
              color: #ebff05;
            }
          }
        }

        // Animation text slide ===========================================================================================================
      }
    }

    .carousel-btn {
      position: absolute;
      top: 50%;
      // transform: translateY(-50%);
      cursor: pointer;
      padding: 10px;
      background: rgba(10, 10, 10, 0.5);
      color: #fff;
      border: none;
      outline: none;
      z-index: 1;

      img {
        width: 20px;
        padding: 0px;
      }
    }

    .prev-btn {
      left: 0;

      img {
        width: 30px;
        transform: rotate(90deg);
        filter: brightness(0.3);
        padding: 10px;

        &:hover {
          filter: brightness(1.0);
        }
      }
    }

    .next-btn {
      right: 0px;

      img {
        width: 30px;
        transform: rotate(-90deg);
        filter: brightness(0.3);
        padding: 10px;

        &:hover {
          filter: brightness(1.0);
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .app {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 1024px;

    .carousel {
      .carousel-slide {
        .imagem {
          width: 1024px;
          &::before{
            width: 1024px;
          }

          .slide-1 {
            width: 1024px;
          }
        }
      }
    }
  }
}
@media (max-width: 913px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 912px;

    .carousel {
      .carousel-slide {
        .imagem {
          width: 912px;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          .slide-1 {
            .title-slide{

            }
            .slide-text-termometria{

            }
          }

          .slide-2 {
            .title-slide-2{
              left: 31%;
            }
            .slide-text-automacao{

            }
          }
        }
      }
    }
  }
}
@media (max-width: 821px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 820px;

    .carousel-slide {
        .imagem {
          width: 820px !important;

          .slide-1 {
            .title-slide{

            }
            .slide-text-termometria{

            }
            .buttonTermometria{
              margin: 25% 0 !important;
            }
          }

          .slide-2 {
              .title-slide-2{

            }
            .slide-text-automacao{

            }
            .buttonAutomcao{
              margin: 25% 0 !important;
            }
          }
        }
      }
  }
}
@media (max-width: 713px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 712px;

    .carousel-slide {
        .imagem {
          width: 712px !important;


          .slide-1 {
            .title-slide{
            }
            .slide-text-termometria{
              margin: 15% 0px !important;
            }
            .buttonTermometria{
              margin: 35% 0 !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 28% !important;
            }
            .slide-text-automacao{
              margin: 15% 0px !important;
            }
            .buttonAutomcao{
              margin: 35% 0 !important;
            }
          }
        }
      }
  }
}
@media (max-width: 601px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 600px;

    .carousel-slide {
        .imagem {
          width: 600px !important;
          height: 450px !important;


          .slide-1 {
            height: 450px !important;
            .title-slide{
            }
            .slide-text-termometria{
              margin: 15% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:40% 0 35% 0  !important;
            }
          }

          .slide-2 {
            height: 100% !important;
            padding: 30px;
            .title-slide-2{
              left: 22% !important;
            }
            .slide-text-automacao{
              margin: 15% 0px !important;
            }
            .buttonAutomcao{
              margin:40% 0 35% 0  !important;
              right: 30% !important;
              left: 30% !important;
            }
          }
        }
      }
  }
}
@media (max-width: 541px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 540px;

    .carousel-slide {
        .imagem {
          width: 540px !important;
          height: 450px !important;


          .slide-1 {
            padding-bottom: 20px !important;
            .title-slide{
            }
            .slide-text-termometria{
              margin: 15% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }

          .slide-2 {
            padding-bottom: 20px !important;
s            .title-slide-2{
              left: 20% !important;
            }
            .slide-text-automacao{
              margin: 15% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }
        }
      }
  }
}
@media (max-width: 481px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 480px;

    .carousel-slide {
        .imagem {
          width: 480px !important;
          height: 450px !important;


          .slide-1 {
            .title-slide{
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 22% 0px 22% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 20% !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 22% 0px 22% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }
        }
      }
  }
}
@media (max-width: 431px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 430px;

    .carousel-slide {
        .imagem {
          width: 430px !important;
          height: 400px !important;


          .slide-1 {
            height: 200px !important;
            .title-slide{
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 20% 0px 22% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }

          .slide-2 {
            height: 200px !important;
            .title-slide-2{
              left: 10% !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 22% 0px 22% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }
        }
      }
  }
}
@media (max-width: 415px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 414px;

    .carousel-slide {
        .imagem {
          width: 414px !important;
          height: 400px !important;


          .slide-1 {
            .title-slide{
              font-size: 50px !important;
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 20% !important;
              font-size: 50px !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
            }
          }
        }
      }
  }
}
@media (max-width: 395px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 394px;

    .carousel-slide {
        .imagem {
          width: 394px !important;
          height: 400px !important;


          .slide-1 {
            .title-slide{
              font-size: 50px !important;
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 20% !important;
              font-size: 50px !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }
        }
      }
  }
}
@media (max-width: 376px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 375px;

    .carousel-slide {
        .imagem {
          width: 375px !important;
          height: 400px !important;


          .slide-1 {
            .title-slide{
              font-size: 50px !important;
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 17% !important;
              font-size: 50px !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }
        }
      }
  }
}
@media (max-width: 361px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 360px;

    .carousel-slide {
        .imagem {
          width: 360px !important;
          height: 400px !important;


          .slide-1 {
            .title-slide{
              font-size: 50px !important;
            }
            .slide-text-termometria{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 14% !important;
              font-size: 50px !important;
            }
            .slide-text-automacao{
              font-size: 20px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:45% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }
        }
      }
  }
}
@media (max-width: 321px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 320px;

    .carousel-slide {
        .imagem {
          width: 320px !important;
          height: 400px !important;


          .slide-1 {
            .title-slide{
              font-size: 35px !important;
            }
            .slide-text-termometria{
              font-size: 16px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:55% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 22% !important;
              font-size: 35px !important;
            }
            .slide-text-automacao{
              font-size: 16px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:55% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }
        }
      }
  }
}
@media (max-width: 281px) {
  .app-mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 280px;

    .carousel-slide {
        .imagem {
          width: 280px !important;
          height: 350px !important;
          .slide-1 {
            .title-slide{
              font-size: 35px !important;
            }
            .slide-text-termometria{
              font-size: 16px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonTermometria{
              right: 30% !important;
              left: 30% !important;
              margin:55% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }

          .slide-2 {
            .title-slide-2{
              left: 22% !important;
              font-size: 35px !important;
            }
            .slide-text-automacao{
              font-size: 16px !important;
              margin: 17% 0px 30% 0px !important;
            }
            .buttonAutomcao{
              right: 30% !important;
              left: 30% !important;
              margin:55% 0 35% 0  !important;
              padding: 5px !important;
              font-size: 15px !important;
            }
          }
        }
      }
  }
}
</style>
