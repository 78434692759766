<template lang="pug">
nav
  router-link.logo(to='/')
    img(src="@/assets/home/logo_sinapse.png")
  .navegacao
    router-link.home(to='/') Home
    //- router-link(to='/termometria') Termometria
    //- router-link(to='/automacao') Automação
    .projetos(v-on:mouseenter="mostrarDropdown", v-on:mouseleave="ocultarDropdown") 
      p.title Projetos
      .dropdown(v-show="dropdownVisivel")
        router-link(to='/automacao') Automação
        router-link(to='/eletrica') Elétrica
        router-link(to='/infraestrutura') Infraestrutura
        //- router-link(to='/eficienciaenergetica') Eficiência energética
        router-link(to='/termometria') Termometria
    router-link(to='/empresa') Empresa
    router-link(to='/clientes') Clientes
    router-link(to='/contato') Contato
    a.acessar-software(href="http://areacliente.sinapsesolucoes.com/#/login") Acessar Software
      img(src="@/assets/icons/acessar-plataforma.svg")
</template>

<script>
export default {
  data() {
    return {
      dropdownVisivel: false
    };
  },
  methods: {
    mostrarDropdown() {
      this.dropdownVisivel = true;
    },
    ocultarDropdown() {
      this.dropdownVisivel = false;
    }
  }
};
</script>

<style lang="scss">
nav {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #000;
    text-decoration: none;
    font-weight: 300;
    font-size: 16px;
    padding: 0 10px;

    &.logo {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      img{
        width: 70px;
        height: 60px;
      }
    }
  }

  .navegacao {
    height: 80px;
    display: flex;
    align-items: center;

    a {
      margin-right: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 500;


      &.router-link-exact-active {
        border-bottom: 2px solid #000;
        padding-top: 2px;
        box-sizing: border-box;

      }
      &.router-link-exact-active.home{
        border-bottom: 2px solid #000;
        padding-top: 2px;
        box-sizing: border-box;
        margin-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .acessar-software {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      width: 194px;
      height: 47px;
      background: #000;
      border-radius: 10px;
      font-weight: bold;
      line-height: 0;
      padding: 0 10px;
      color: #fff;
      cursor: pointer;

      img {
        width: 14px;
        margin-left: 5px;
      }
    }
    .projetos{
      position: relative;
      padding-top: 2px;
      box-sizing: border-box;
      margin-right: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 300;
      font-size: 16px;
      .title{
        font-weight: 500;
      }
      .dropdown {
        position: absolute;
        top: 70%;
        left: 0;
        width: 150px;
        background-color: #000;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        animation: dropDown 0.4s forwards;
        margin-top: 10px;
        padding: 0px 10px 0px 10px;

        @keyframes dropDown {
          0%{
            opacity: 0;
            transform: translateY(-20%);
          }
          100%{
            opacity: 1;
            transform: translateY(0);
          }
          0%{
            opacity: 0;
            transform: translateY(-20%);
          }
        }

        &.hide{
          animation: dropUp 1s forwards;
        }

        @keyframes dropUp {
          0%{
            opacity: 1;
            transform: translateY(0);
          }
          100%{
            opacity: 0;
            transform: translateY(-20%);
          }
        }
      a {
        display: block;
        color: #fff;
        background: #000;
        text-decoration: none;
        padding:5px 160px 0 10px;
        // /* width: 114%; */
        position: relative;
        left: -10px;
        text-align: start;
        font-weight: 500;
        overflow: hidden;
        height: auto;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #fff;
          color: #000;
          font-weight: 500;
        }
      }
    }
    .dropdown.show {
      display: block;
    }
    }
  }
}
</style>
