import Vue from "vue";
import VueRouter from "vue-router";
// import Termometria from "../views/Termometria.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/eletrica",
    name: "Elétrica",
    component: () => import("../views/Eletrica.vue"),
  },
  {
    path: "/infraestrutura",
    name: "Infraestrutura",
    component: () => import("../views/Infraestrutura.vue"),
  },
  {
    path: "/eficienciaenergetica",
    name: "Eficiência Energetica",
    component: () => import("../views/EficienciaEnergetica.vue"),
  },
  {
    path: "/termometria",
    name: "Termometria",
    component: () => import("../views/Termometria.vue"),
  },
  {
    path: "/automacao",
    name: "Automacão",
    component: () => import("../views/Automacao.vue"),
  },
  {
    path: "/empresa",
    name: "Empresa",
    component: () => import("../views/Empresa.vue"),
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import("../views/Clientes.vue"),
  },
  {
    path: "/contato",
    name: "Contato",
    component: () => import("../views/Contato.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
